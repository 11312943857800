import React, { useState } from "react"
import Wrapper from "../components/wrapper";
import SectionTitle from "../components/sectionTitle";


const Hello = ({location}) => {

    const [formClick, setFormClick] = useState(false)

    return (
        <Wrapper
            customTitle={"Project Brief Form | Website Design & Development Company in Kuala Lumpur, Malaysia | Appleseeds"}
            customDescription={"We are highly-skilled website designers and developers who are passionate in web design and development. Often, people wonder how a small team like ours can produce work in such caliber. And all we can say is, passion goes a long way."}
            customKeyword={"web design malaysia, website designing kl, webpage designer, creative website design, website design company, web page design, website agency, creative agency, hotel website design"}
            customOgImage={'/cover-about.jpg'}
            contactForm={false}
            slug={location.pathname}
        >
            <footer>
                <section id="contact" className="section-contact">
                    <div className="container mx-auto transition-ease-in">
                        {formClick === false ?
                            <div className="flex flex-wrap">
                                <SectionTitle
                                    wrapperClassName="md:w-7/12 md:mt-20 mb-5"
                                    alignCenter={true}
                                    title="Let’s work."
                                    minititle=""
                                    type="h3"
                                    invert={false}
                                    childrenClassName=""
                                    buttonText=""
                                    buttonType=""
                                    buttonLink=""
                                >
                                    <p className="mb-5">We’ll be glad to explore the possibility of working together. Let us know more about you and your project so we can get back to you with rough estimation of scope, timeline, and cost.</p>
                                </SectionTitle>

                                <form action="https://formspree.io/hello@appleseeds.my" method="POST" className="w-full">
                                    <fieldset className="flex flex-col w-full md:w-2/3 lg:w-3/5 space-y-2 mx-auto">
                                        <div>
                                            <label htmlFor="name">Name</label>
                                            <input type="text" name="name" required/>
                                            {/* <ValidationError prefix="name" field="name" errors={state.errors} /> */}
                                        </div>
                                        <div>
                                            <label htmlFor="email">Email</label>
                                            <input type="email" name="email" required />
                                            {/* <ValidationError prefix="Email" field="email" errors={state.errors} /> */}
                                        </div>
                                        <div>
                                            <label htmlFor="phone">Contact Number</label>
                                            <input type="tel" pattern="[0-9]*" name="phone" required />
                                            {/* <ValidationError prefix="Phone" field="phone" errors={state.errors} /> */}
                                        </div>
                                        <div>
                                            <label htmlFor="organisation">Organisation Name</label>
                                            <input type="text" name="organisation" required />
                                            {/* <ValidationError prefix="Organisation" field="organisation" errors={state.errors} /> */}
                                        </div>
                                        <div className="flex flex-row items-center space-x-4 pb-3">
                                            <label htmlFor="budget" className="">Budget</label>
                                            <select style={{marginBottom:0}} defaultValue="RM10k &mdash; RM30k" name="budget" >
                                                <option>&lt; RM10k</option>
                                                <option>RM10k &mdash; RM30k</option>
                                                <option>RM30k &mdash; RM70k</option>
                                                <option>RM70k &mdash; RM100k</option>
                                                <option>&gt; RM100k</option>
                                            </select>
                                        </div>
                                        <div>
                                            <label htmlFor="timeline">Timeline</label>
                                            <input type="text" name="timeline" required />
                                            {/* <ValidationError prefix="Timeline" field="timeline" errors={state.errors} /> */}
                                        </div>
                                        <div>
                                            <label htmlFor="existing">Existing Website/App (If Any)</label>
                                            <input type="text" name="existing" />
                                        </div>

                                        <input type="text" name="_gotcha" style={{ display: 'none' }} />
                                        <input type="hidden" name="_next" value="https://www.appleseeds.my/thank-you/" />

                                        <div>
                                            <label htmlFor="description">Project Description</label>
                                            <textarea name="description" required></textarea>
                                        </div>
                                        <div>
                                            <label htmlFor="website-problem">Existing Website Issues (Optional)</label>
                                            <textarea name="website-problem" ></textarea>
                                        </div>
                                        <div>
                                            <label htmlFor="references">Websites/Apps You Like (Optional)</label>
                                            <textarea name="references"></textarea>
                                        </div>
                                        
                                        <div className="w-full">
                                            <button type="submit" 
                                                // onClick={()=>{setFormClick(true)}} 
                                                className={`w-full md:w-auto float-left md:float-right btn btn-filled`}>
                                                Send
                                                {/* {state.submitting ? 'Submitting...' : 'Send'} */}
                                            </button>
                                        </div>
                                        
                                        {/* {formClick && 
                                            // {state.errors &&
                                                <div className="pt-2 text-center text-sm text-red-500 w-full">
                                                    <p>
                                                        Error: {state.errors && `${state.errors[0]}`}
                                                    </p>
                                                </div>
                                            // }
                                        } */}
                                        
                                    </fieldset>
                                </form>
                            </div>

                            :

                                                            // This section is not working, submit button onClick function is commented as we are using old formspree page redirect method
                            <SectionTitle
                                wrapperClassName="lg:w-full mt-20 mb-5 h-min-96"
                                alignCenter={false}
                                title={['Thank you for contacting us,', <br className="hidden sm:block"/>, 'we will get back to you soon.']}
                                minititle="Contact Us"
                                type="h3"
                                invert={false}
                                childrenClassName=""
                                buttonText="Back to Home"
                                buttonType="btn-filled"
                                buttonLink="/"
                            >
                            </SectionTitle>

                        }
                    </div>
                </section>
            </footer>
        </Wrapper>
    )
}

export default Hello;